// ScheduleColumn.js
import React, { useState, useEffect, useRef } from 'react';
import './MatchSchedule.css';
import TextField from '@mui/material/TextField';
import { useDrag, useDrop } from 'react-dnd';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { CalendarIcon } from '@mui/x-date-pickers';
import { ExpandLess, ExpandMore, Label } from '@mui/icons-material';
import DropZone from './DropZone';
import WarningIcon from '@mui/icons-material/Warning';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ScheduleColumnSummary from './ScheduleColumnSummary';
import DroppableJob from './DroppableJob';
import { IconButton} from '@mui/material';

// ScheduleColumn Component
function ScheduleColumn({ columnIndex, selectedDate, crewColors, crews, assignCrewToJob, moveJob, jobs, isPastDue,  employees, isCollapsed, onToggleCollapse, setRefresh }) {
  //const [selectedDate, setSelectedDate] = useState(date);
  // Notify MatchSchedule of the initial date
  // useEffect(() => {
  //   setSelectedDate(selectedDate);
  //   // onDateChange(columnIndex, selectedDate);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  // const handleDateChange = (newDate) => {
  //   setSelectedDate(newDate);
  // };

  return (
    <div className={"match-schedule-column schedule " + (isCollapsed ? "collapsed" : "")}>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        
        {!isPastDue && !isCollapsed && (
          <span className='title'>{selectedDate.format('ddd')} {selectedDate.format('MM-DD')}</span>
        )}
        {isPastDue && !isCollapsed && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='title' style={{ marginLeft: '15px', textDecoration: 'underline' }}>
              Past Due
            </span>
          </div>
        )}
        <IconButton onClick={onToggleCollapse} size="small">
          {
            isCollapsed
              ? isPastDue
                ? <WarningIcon />
                : <CalendarIcon />
              : <ExpandLess />
          }
        </IconButton>
      </div> */}
      {!isCollapsed && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* {!isPastDue && (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} style={{ marginBottom: '20px' }} />
                )} />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <IconButton onClick={() => setSelectedDate(dayjs(selectedDate).add(1, 'day'))} size="small" style={{ marginLeft: '10px' }}>
                  <AddIcon />
                </IconButton>
                <IconButton onClick={() => setSelectedDate(dayjs(selectedDate).subtract(1, 'day'))} size="small" style={{ marginLeft: '10px' }}>
                  <RemoveIcon />
                </IconButton>
              </div>
            </div>
          )} */}

          {/* <ScheduleColumnSummary total={jobs?.length} totalComplete={jobs?.filter(job => job.date_scheduled != null)?.length} ></ScheduleColumnSummary> */}
          {jobs.length === 0 ? (
            <DropZone
              date={selectedDate}
              index={0}
              moveJob={moveJob}
              assignCrewToJob={assignCrewToJob}
            />
          ) : (
            jobs.map((job, index) => (
              <React.Fragment key={job.id}>
                <DropZone
                  date={selectedDate}
                  index={index}
                  moveJob={moveJob}
                  assignCrewToJob={assignCrewToJob}
                />
                <DroppableJob
                  job={job}
                  index={index}
                  date={selectedDate}
                  crewColors={crewColors}
                  moveJob={moveJob}
                  assignCrewToJob={assignCrewToJob}
                  employees={employees}
                  setRefresh={setRefresh}
                />
              </React.Fragment>
            ))
          )}
          {jobs.length > 0 && (
            <DropZone
              date={selectedDate}
              index={jobs.length}
              moveJob={moveJob}
              assignCrewToJob={assignCrewToJob}
            />
          )}
        </LocalizationProvider>
      )}
    </div>
  );
}
export default ScheduleColumn;