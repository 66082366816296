// src/components/schedule3/MatchSchedule.js

import React, { useEffect, useState, useCallback } from 'react';
import './MatchSchedule.css';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CentralCalls from '../../centralCalls';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Tooltip from '@mui/material/Tooltip';

function DraggableCrewList({ crew, onClick, onDelete, showDelete, isSelected, index, crewColors = [] }) {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'CREW_LIST',
        item: crew.length > 0 ? { ids: crew.map(employee => employee.oid), index, type: 'CREW_LIST' } : { index, type: 'CREW_LIST' },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [crew, index]);

    const getInitials = (employee) => {
        if (!employee || typeof employee.name !== 'string' || employee.name.trim() === "") return "";

        const nameParts = employee.name.trim().split(/\s+/); // Split by spaces

        if (nameParts.length > 1) {
            return (nameParts[0][0] + nameParts[1][0]).toUpperCase(); // First letter of first and last name
        } else {
            return nameParts[0].substring(0, 2).toUpperCase(); // First two letters of single name
        }
    };
    const getTeamName = (crew) => {
        console.log('teamname: crew...')
        console.log(crew);
        if(!crew || crew.length < 1){return '-';}
        const nameParts = crew[0].name.trim().split(/\s+/);
        const displayName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0]; 
        return displayName;
    };
    // Last name or first if no last name

    return (
        <div
            ref={crew.length > 0 ? drag : null}
            onClick={onClick}
            className="draggable-crew-list"
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: crew.length > 0 ? 'grab' : 'default',
                padding: '5px',
                border: isSelected ? '3px solid #0277bd' : '1px solid #ccc',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                position: 'relative',
                backgroundColor: crewColors[index % crewColors.length],
            }}
        >
            {/* Top row: Delete Button & Right-Aligned Content */}
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {/* Left-Aligned Delete Button */}
                {showDelete && (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}
                        size="small"
                       
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                )}
                
                {/* Right-Aligned Content */}
                <div style={{ marginLeft: 'auto' }}>
                {(crew && crew.length > 0) && (<Chip label={'Team: ' + getTeamName(crew)} />)}
                </div>
            </div>
    
            {/* Crew Chips (Now Below the Top Row) */}
            {crew.length > 0 && (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '5px' }}>
                    {crew.map((employee) => (
                        <Tooltip key={employee.oid} title={employee.name} arrow>
                            <Chip label={getInitials(employee)} />
                        </Tooltip>
                    ))}
                </div>
            )}
        </div>
    );
    
}

export default DraggableCrewList;
