import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Tooltip, Checkbox, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CentralCalls from '../../centralCalls';

const BuilderFilter = ({ onBuilderSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [builders, setBuilders] = useState([]);
    const [selectedBuilders, setSelectedBuilders] = useState([]);

    useEffect(() => {
        CentralCalls.fetchTableData('builder', {}).then(response => {
            setBuilders(response);
        });
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleBuilderToggle = (builder) => {
        const selectedOids = selectedBuilders.includes(builder.oid)
            ? selectedBuilders.filter(oid => oid !== builder.oid) // Remove if already selected
            : [...selectedBuilders, builder.oid]; // Add if not selected
        
        setSelectedBuilders(selectedOids);
        console.log('bulider list...')
        console.log(selectedOids);
        onBuilderSelect(selectedOids); // Pass as array
    };

    const handleClearSelection = () => {
        setSelectedBuilders([]);
        onBuilderSelect([]); // Empty array means no filter applied
    };
    const getShortName = (name) => {
        const words = name.split(' ');
        if (words.length > 1) {
            return words[0][0].toUpperCase() + words[1][0].toUpperCase();
        }
        return words[0].substring(0, 2).toUpperCase();
    };
    return (
        <div>
            <Tooltip title={selectedBuilders.length ? selectedBuilders.map(oid => getShortName(builders.find(b => b.oid === oid)?.name || '')).join(', ') : 'Select Builder(s)'}>
                <IconButton
                    onClick={handleMenuOpen}
                    sx={{
                        fontSize: 13,
                        backgroundColor: '#ccc',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#aaa',
                            color: 'white'
                        },
                        minWidth: '30px', height: '30px'
                    }} >
                    {selectedBuilders.length
                        ? selectedBuilders.map(oid => getShortName(builders.find(b => b.oid === oid)?.name || '')).join(', ')
                        : <FilterListIcon />}
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleClearSelection} dense>
                    <Checkbox checked={selectedBuilders.length === 0} />
                    <ListItemText primary="All (No Filter)" />
                </MenuItem>
                {builders.sort((a, b) => a.name.localeCompare(b.name)).map(builder => (
                    <MenuItem key={builder.oid} onClick={() => handleBuilderToggle(builder)}>
                        <Checkbox checked={selectedBuilders.includes(builder.oid)} />
                        <ListItemText primary={builder.name} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default BuilderFilter;
