// ScheduleColumn.js
import React, { useState, useEffect, useRef } from 'react';
import './MatchSchedule.css';
import TextField from '@mui/material/TextField';
import { useDrag, useDrop } from 'react-dnd';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import { CalendarIcon } from '@mui/x-date-pickers';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ExpandLess, ExpandMore, Label } from '@mui/icons-material';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropZone from './DropZone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { faClock, faCalendarCheck, faHourglassStart,faClipboard, faPause, faPauseCircle, faPlay, faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { EventNote } from '@mui/icons-material';
import ScheduleColumnSummary from './ScheduleColumnSummary';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@mui/material';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DynamicForm from '../DynamicForm';
import JSONTableEditor from '../JSONTableEditor';
import CentralCalls from '../../centralCalls';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faExplosion } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
const ItemTypes = {
    JOB: 'JOB',
    CREW_LIST: 'CREW_LIST',
};

function DroppableJob({ job, index, date, crewColors, moveJob, assignCrewToJob, employees, refreshMenu, setRefresh }) {
    const ref = useRef(null);
    //const [PhaseOid, setPhaseOid] = useState(null);
    //const [LotOid, setLotOid] = useState(null);
    // const [phaseType, setPhaseType] = useState('');
    const [refreshUndergroundKey, setRefreshUndergroundKey] = useState(0);
    const [UndergroundDialogOpen, setUndergroundDialogOpen] = useState(false);
    const [lotTitle, setLotTitle] = useState('no title');
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
    const navigate = useNavigate();
    const [{ isOver }, drop] = useDrop({
        accept: [ItemTypes.JOB, ItemTypes.CREW_LIST],
        hover: (item, monitor) => {
            // OPTIONAL: If you want live “highlighting” or any hover-based logic, do it here.
            // Just DON’T call moveJob here if you only want a final drop-based reorder.
        },
        drop: (item, monitor) => {
            if (!ref.current || !item) return;

            if (item.type === ItemTypes.JOB) {
                const dragDate = item.date;
                const dragIndex = item.index;
                const dropDate = date.format('YYYY-MM-DD');
                const dropIndex = index;

                // Only reorder if it's a different spot
                if (dragDate === dropDate && dragIndex === dropIndex) return;

                // Finalize the move on drop
                moveJob(dragDate, dragIndex, dropDate, dropIndex);

                // Update item to reflect new index/date
                item.index = dropIndex;
                item.date = dropDate;
            }
            else if (item.type === ItemTypes.CREW_LIST) {
                const crewIndex = item.index;
                assignCrewToJob(date.format('YYYY-MM-DD'), index, crewIndex);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });
    const handleWipLinkClick = async () => {
        const path = '/wip?lot_oid=' + job.lot_oid;
        window.open(path, '_blank');
    };

    useEffect(() => {
        const getLotTitle = async () => {
            if (UndergroundDialogOpen) {  // Only run when dialog opens
                const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: job.lot_oid });
                setLotTitle(title.title);
            }
        };
        if (UndergroundDialogOpen) { // Ensure it only runs when opening
            getLotTitle();
        }
    }, [UndergroundDialogOpen]);  // Only runs when StatusDialogOpen changes

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.JOB,
        item: {
            id: job.id,
            index,
            date: date.format('YYYY-MM-DD'),
            type: ItemTypes.JOB
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    const opacity = isDragging ? 0.5 : 1;
    // Get employee names
    const employeeNames = employees
        .filter(emp => job.employee_oids && job.employee_oids.includes(emp.oid))
        .map(emp => emp.name);
    const handleCompletephaseClose = async (phaseRow) => {
        setStatusDialogOpen(false);

        //setPhaseOid(phaseRow.phase_oid);
        if (phaseRow.phase_type === 'slab') {
            const updateObj = {
                oid: job.lot_oid,
                sewer_location: phaseRow.sewer_location,
                water_location: phaseRow.water_location,
            };
            try {
                const response = await fetch('/api/update', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ tableName: 'lot', updateObj: updateObj }),
                });
                const data = await response.json();
                setRefresh();
            } catch (err) {
                console.error(err);
                // Handle error
            }
        }
        if (phaseRow.finished === 1 && (phaseRow.status === 'fail')) {
            const insertObj = {
                phase_oid: phaseRow.phase_oid,
                comments: phaseRow.comments,
                employee_oids: JSON.stringify(phaseRow.employee_oids)
            };
            await CentralCalls.upsertData(insertObj, 'phase_status', 'insert');
            setRefresh(new Date().getTime());
        }
        if (phaseRow.isQc === 1 || phaseRow.isInspection === 1) {
            await CentralCalls.upsertData({ 'oid': phaseRow.phase_oid, 'qc_status': phaseRow.qc_status }, 'phase', 'update');
        }
        if ((phaseRow.isQc === 1 || phaseRow.isInspection === 1) && phaseRow.qc_status === 'fail') {
            await failedQCAutoPunch(phaseRow);
        }
        setRefresh();
    };
    const procedureDetailsCompletionInsert = {
        procedureName: 'ui_sp_getPhaseCompletionPageDataInsert',
        parameters: { phase_oid: job.phase_oid, phase_type: job.phase_type, lot_oid: job.lot_oid },  // If there are any parameters, define them here as an object
    };
    const handleSavePhaseSaveEdit = async (phaseRow) => { }
    const procedureDetailsCompletion = {
        procedureName: 'ui_sp_getPhaseCompletionPageData',
        parameters: { phase_oid: job.phase_oid, lot_oid: job.lot_oid },  // If there are any parameters, define them here as an object
    };
    const failedQCAutoPunch = async (phaseRow) => {
        const punchPhaseType = phaseRow.phase_type.split('_')[0];
        const newPhase = await CentralCalls.upsertData({ 'lot_oid': phaseRow.lot_oid, 'phase_type': punchPhaseType, 'is_punch': 1, 'punch_comments': phaseRow.comments, 'builder_date': phaseRow.date_scheduled }, 'phase', 'insert');
        await CentralCalls.upsertData({ 'phase_oid': newPhase.oid }, 'phase_status', 'insert');
    }
    const getPhaseStatusIcon = () => {
        if (!job) { return; }
        if (job.ready_status === 'upcoming') {
            // If is_active = 0, return "Upcoming" (Overrides all other conditions)
            return <span style={{ padding: '3px', borderRadius: '10px', backgroundColor: '#FFA000', color: 'white' }}> <Tooltip title='Upcoming' arrow><FontAwesomeIcon icon={faHourglassStart} /></Tooltip></span>;
        }
        else if (job.ready_status === 'hold') {
            // If is_active = 0, return "Upcoming" (Overrides all other conditions)
            return <span style={{ padding: '3px', borderRadius: '10px', backgroundColor: '#FFA000', color: 'white' }}><Tooltip title='On Hold' arrow><FontAwesomeIcon icon={faPauseCircle} /></Tooltip></span>;
        }
        else if (job.ready_status === 'cancel') {
            // If is_active = 0, return "Upcoming" (Overrides all other conditions)
            return <span style={{ padding: '3px', borderRadius: '10px', backgroundColor: '#FFA000', color: 'white' }}><Tooltip title='Cancelled' arrow><FontAwesomeIcon icon={faBan} /></Tooltip></span>;
        }
        else if (job.ready_status === 'open') {
            // If date_scheduled or employee_oids is null AND status = open, return "Open"
            return <span style={{ padding: '3px', borderRadius: '10px', backgroundColor: '#FFA000', color: 'white' }}><Tooltip title='Open' arrow><FontAwesomeIcon icon={faClock} /></Tooltip></span>;
        }
        else if (job.ready_status === 'scheduled') {
            // If date_scheduled is not null AND employee_oids is not null AND status = open, return "Scheduled"
            return <span style={{ padding: '3px', borderRadius: '10px', backgroundColor: '#FFA000', color: 'white' }}><Tooltip title='Scheduled' arrow><FontAwesomeIcon icon={faCalendarCheck} /></Tooltip></span>;
        }
        return <Label>NOTHING</Label>
    };
    const handlePutOnHold = async () => {
        const confirmCancellation = window.confirm("Are you sure you want to put this job on hold?");
        if (confirmCancellation) {
            await CentralCalls.upsertData({ 'oid': job.phase_status_oid, 'status': 'hold', 'status_date': new Date() }, 'phase_status', 'update');
            job.phase_status = 'hold';
            //setRefresh(new Date().getTime());
        }
    }

    const handleRemoveOnHold = async () => {
        const confirmCancellation = window.confirm("Are you sure you want to set back to active?");
        if (confirmCancellation) {
            await CentralCalls.upsertData({ 'oid': job.phase_status_oid, 'status': 'open' }, 'phase_status', 'update');
            //setRefreshKey(!refreshKey);
            //refreshMenu();
        }
    }
    const handleRemoveDeleted = async () => {
        const confirmCancellation = window.confirm("Are you sure you want Revert back to Active?");
        if (confirmCancellation) {
            await CentralCalls.upsertData({ oid: job.phase_status_oid, status: 'open' }, 'phase_status', 'update');
        }
        // refreshMenu();
    }
    const handleDeleteRow = async () => {
        const confirmCancellation = window.confirm("Are you sure you want Delete this Job?");
        if (confirmCancellation) {
            await CentralCalls.upsertData({ oid: job.phase_status_oid, status: 'cancel' }, 'phase_status', 'update');
        }
    }
    const handleSaveUndergroundHistory = async (row) => { }
    const handleDisplayUndergroundApprovalClick = async (row) => {
        //setRefresh(new Date().getTime());
        setUndergroundDialogOpen(true);
        //refreshMenu();
    }
    const handleCompleteUndergroundClose = async (row) => {
        setUndergroundDialogOpen(false);
    }
    const procedureDetailsUndergroundTickets = {
        procedureName: 'ui_getUndergroundTickets',
        parameters: { phase_oid: job.phase_oid, lot_oid: job.lot_oid }
    }
    // const procedureDetailsCompletion = {
    //     procedureName: 'ui_sp_getPhaseCompletionPageData',
    //     parameters: { phase_oid: PhaseOid, lot_oid: LotOid },  // If there are any parameters, define them here as an object
    // };
    // const procedureDetailsCompletionInsert = {
    //     procedureName: 'ui_sp_getPhaseCompletionPageDataInsert',
    //     parameters: { phase_oid: PhaseOid, phase_type: phaseType, lot_oid: LotOid },  // If there are any parameters, define them here as an object
    // };
    const procedureDetailsUndergroundHistory = {
        procedureName: 'ui_sp_wipUndergroundTickets',
        parameters: { lot_oid: job.lot_oid },
    }
    return (
        <div
            ref={ref}
            className="droppable-job"
            style={{
                padding: '5px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                opacity,
                backgroundColor: '#f0f0f0',
                transition: 'background-color 0.2s ease, opacity 0.2s ease',
                marginBottom: '0px',
                cursor: 'move',
            }} >

            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px' }}>
                    <span style={{ fontSize: '11px' }}>
                        {getPhaseStatusIcon()} | {index + 1} |
                        <Tooltip title="View in WIP" arrow>
                            <IconButton
                                className="recent-lots-button"
                                color="primary"
                                aria-label="save"
                                size="small"
                                onClick={handleWipLinkClick}
                                style={{ padding: '0px' }}>
                                <FontAwesomeIcon
                                    icon={faEye}
                                    style={{ fontSize: '11px' }} />
                            </IconButton>
                        </Tooltip>
                        {job.ready_status === 'scheduled' && (
                            <>
                                | <Tooltip title="Complete Job" arrow>
                                    <IconButton
                                        className="recent-lots-button"
                                        color="primary"
                                        aria-label="save"
                                        size="small"
                                        onClick={() => setStatusDialogOpen(true)}
                                        style={{ padding: '3px' }} >
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            style={{ fontSize: '11px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {job.ready_status !== 'hold' && (
                            <>|
                                <Tooltip title="Put on Hold" arrow>
                                    <IconButton
                                        className="recent-lots-button"
                                        color="primary"
                                        aria-label="save"
                                        size="small"
                                        onClick={() => handlePutOnHold()}
                                        style={{ padding: '3px' }} >
                                        <FontAwesomeIcon
                                            icon={faPause}
                                            style={{ fontSize: '11px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {job.ready_status === 'hold' && (
                            <>|
                                <Tooltip title="Set to Active" arrow>
                                    <IconButton
                                        className="recent-lots-button"
                                        color="primary"
                                        aria-label="save"
                                        size="small"
                                        onClick={() => handleRemoveOnHold()}
                                        style={{ padding: '3px' }} >
                                        <FontAwesomeIcon
                                            icon={faPlay}
                                            style={{ fontSize: '11px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {job.ready_status !== 'cancel' && (
                            <>|
                                <Tooltip title="Delete Job" arrow>
                                    <IconButton
                                        className="recent-lots-button"
                                        color="primary"
                                        aria-label="save"
                                        size="small"
                                        onClick={() => handleDeleteRow()}
                                        style={{ padding: '3px' }} >
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{ fontSize: '11px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {job.ready_status === 'cancel' && (
                            <>|
                                <Tooltip title="Revert to Active" arrow>
                                    <IconButton
                                        className="recent-lots-button"
                                        color="primary"
                                        aria-label="save"
                                        size="small"
                                        onClick={() => handleRemoveDeleted()}
                                        style={{ padding: '3px' }} >
                                        <FontAwesomeIcon
                                            icon={faTrashRestore}
                                            style={{ fontSize: '11px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    {job.phase_type_id === 'underground' && (
                            <>
                                <Tooltip title="View Tickets" arrow>
                                    <IconButton
                                        className="recent-lots-button"
                                        color="primary"
                                        aria-label="save"
                                        size="small"
                                        onClick={() => setUndergroundDialogOpen(true)}
                                        style={{ padding: '3px' }} >
                                        <FontAwesomeIcon
                                            icon={faClipboard}
                                            style={{ fontSize: '11px' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    {job.is_punch === 1 && (
                            <Tooltip title={job.punch_comments || "punch"}>
                                <ReportProblemIcon style={{ fontSize: '16px', color: '#f57c00' }} />
                            </Tooltip>
                        )}
                        <Tooltip title={job.comments || "-"}>
                            <Chip
                                style={{
                                    fontSize: '11px',
                                    height: '18px',
                                    lineHeight: '1',
                                    padding: '2px 6px',
                                    backgroundColor: '#0B426B',
                                    color: 'white',
                                    minWidth: '0',
                                }}
                                label={job.phase_type}
                            />
                        </Tooltip>
                    </div>

                </div>
                <div style={{
                    marginTop: '3px',
                    borderTop: '2px solid #ccc',
                    backgroundColor: job.crewIndex !== undefined ? crewColors[job.crewIndex % crewColors.length] : '#f0f0f0',
                    fontSize: '11px',
                    textAlign: 'left',
                    display: 'flex',   // Use flex to align items horizontally
                    flexWrap: 'wrap',
                    gap: '5px',
                    alignItems: 'center' // Align vertically in center
                }}>
                    {/* Left-aligned content */}
                    <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', wordBreak: 'break-word', minWidth: '0' }}>
                        <div>{job.builder} |</div>
                        <div>{job.neighborhood} |</div>
                        <div>{job.lot_num}</div>
                    </div>
                    {/* Right-aligned Chip */}
                    {employeeNames.length > 0 && (() => {
                        const nameParts = employeeNames[0].trim().split(/\s+/);
                        const displayName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0]; // Last name or first if no last name
                        return (
                            <Chip
                                style={{
                                    fontSize: '11px',
                                    padding: '5px 4px',
                                    marginTop: '5px',
                                    minWidth: '0',
                                    marginLeft: 'auto' // Pushes it to the right
                                }}
                                key={0}
                                label={`Team: ${displayName}`}
                                size="small"
                            />
                        );
                    })()}
                </div>
            </div>
            {/* {employeeNames.length > 0 && (
                <div style={{
                    borderTop: '2px solid #ccc',
                    backgroundColor: job.crewIndex !== undefined ? crewColors[job.crewIndex % crewColors.length] : '#f0f0f0',
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'flex-end', 
                    alignItems: 'center',
                    gap: '5px'
                }}>
                    {employeeNames.length > 0 && (() => {
                        const nameParts = employeeNames[0].trim().split(/\s+/);
                        const displayName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0]; // Last name or first if no last name
                        return (
                            <Chip
                                style={{
                                    fontSize: '11px',
                                    padding: '5px 4px',
                                    marginTop: '5px',
                                    minWidth: '0',
                                }}
                                key={0}
                                label={`Team: ${displayName}`}
                                size="small"
                            />
                        );
                    })()}
                </div>
            )} */}
            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>Finish Task</h2>
                        <h4>{lotTitle}</h4>
                        <DynamicForm procedureDetails={procedureDetailsCompletionInsert}
                            onSave={handleCompletephaseClose}
                            saveName='Complete'
                            crudType='update' ></DynamicForm>
                        <h2>Task History</h2>
                        <JSONTableEditor
                            procedureDetails={procedureDetailsCompletion}
                            onSave={handleSavePhaseSaveEdit}
                            sortOrder={{ 'date_complete': 'desc' }} >
                        </JSONTableEditor>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth='lg' open={UndergroundDialogOpen} onClose={() => setUndergroundDialogOpen(false)}>
                <DialogContent >
                    <>
                        <h2>Tickets</h2>
                        <h4>{lotTitle}</h4>
                        <JSONTableEditor
                            key={refreshUndergroundKey + 1}
                            procedureDetails={procedureDetailsUndergroundHistory}
                            onSave={handleSaveUndergroundHistory}
                            allowDelete={true}
                            sortOrder={{
                                'oid': 'desc'
                            }} >
                        </JSONTableEditor>
                        <h2>New Ticket
                        </h2>
                        <DynamicForm
                            key={refreshUndergroundKey}
                            procedureDetails={procedureDetailsUndergroundTickets}
                            onSave={handleCompleteUndergroundClose}
                            saveName='Insert'
                            crudType='insert' ></DynamicForm>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUndergroundDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default DroppableJob;