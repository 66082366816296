import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Tooltip, Checkbox, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CentralCalls from '../../centralCalls';

const PhaseFilter = ({ onPhaseSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [phaseTypes, setPhaseTypes] = useState([]);
    const [selectedPhases, setSelectedPhases] = useState([]);

    const inspection = {
        phase_order: 12,
        is_inspection: 0,
        short_name: 'I',
        name_id: 'slab_inspection',
        menu_name: 'Inspections',
        header_back_color: '#0B426B',
        main_back_color: '#F0F0F5',
        header_text_color: 'white'
    };

    const all = {
        phase_order: 0,
        is_inspection: 0,
        short_name: '*',
        name_id: '',
        menu_name: 'All',
        header_back_color: '#0B426B',
        main_back_color: '#F0F0F5',
        header_text_color: 'white'
    };

    useEffect(() => {
        CentralCalls.fetchTableData('phase_type', { is_inspection: 0 }).then(response => {
            const inspectionExists = response.some(phase => phase.name_id === inspection.name_id);
            setPhaseTypes(inspectionExists ? response : [all, ...response, inspection]);
        });
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePhaseToggle = (phase) => {
        const updatedSelected = selectedPhases.includes(phase.name_id)
            ? selectedPhases.filter(id => id !== phase.name_id) // Remove if already selected
            : [...selectedPhases, phase.name_id]; // Add if not selected

        setSelectedPhases(updatedSelected);
        onPhaseSelect(updatedSelected); // Return updated array
    };

    const handleClearSelection = () => {
        setSelectedPhases([]);
        onPhaseSelect([]); // Send empty array when cleared
    };

    return (
        <div>
            <Tooltip title={selectedPhases.length ? selectedPhases.map(id => phaseTypes.find(p => p.name_id === id)?.short_name).join(', ') : 'Select Phase'}>
                <IconButton
                    onClick={handleMenuOpen}
                    sx={{
                        fontSize: 13,
                        backgroundColor: selectedPhases.length ? '#0B426B' : '#ccc',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: selectedPhases.length ? '#0B426B' : '#aaa',
                            color: 'white'
                        },
                        minWidth: '30px',
                        height: '30px'
                    }} >
                    {selectedPhases.length
                        ? selectedPhases.map(id => phaseTypes.find(p => p.name_id === id)?.short_name).join(', ')
                        : <FilterListIcon />}
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleClearSelection} dense>
                    <Checkbox checked={selectedPhases.length === 0} />
                    <ListItemText primary="All (No Filter)" />
                </MenuItem>
                {phaseTypes.sort((a, b) => a.phase_order - b.phase_order).map(phase => (
                    <MenuItem key={phase.name_id} onClick={() => handlePhaseToggle(phase)}>
                        <Checkbox checked={selectedPhases.includes(phase.name_id)} />
                        <ListItemText primary={`${phase.short_name} - ${phase.menu_name}`} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default PhaseFilter;
