// src/components/schedule3/MatchSchedule.js

import React, { useEffect, useState,useRef, useCallback } from 'react';
import './MatchSchedule.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import PeopleIcon from '@mui/icons-material/People';
import RemoveIcon from '@mui/icons-material/Remove';
import { CalendarIcon } from '@mui/x-date-pickers';
import { ExpandLess, ExpandMore, Label } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import CentralCalls from '../../centralCalls';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import dayjs from 'dayjs';
import ActivePhaseButtonGroup from '../phase/activePhaseButtonGroup';
import FilterButtonGroup from '../phase/filterButtonGroup';
import ScheduleColumn from './ScheduleColumn'; // Ensure the path is correct
import DraggableCrewList from './DraggableCrewList';
import PhaseFilter from './PhaseFilter';
import ReadyStatFilter from './ReadyStatFilter';
import BuilderFilter from './BuilderFilter';
import ScheduleColumnSummary from './ScheduleColumnSummary';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const crewColors = [
    '#ffebee', '#f8bbd0', '#e1bee7', '#d1c4e9', '#bbdefb',
    '#b3e5fc', '#b2dfdb', '#dcedc8', '#fff9c4', '#ffe0b2'
];

function MatchSchedule() {
    const [employees, setEmployees] = useState([]);
    const [crews, setCrews] = useState([[]]);
    const [selectedCrewIndex, setSelectedCrewIndex] = useState(0);
    const [refreshKey, setRefreshKey] = useState(0);
    const [filterPhaseType, setFilterPhaseType] = useState([]);
    const [filterBuilder, setFilterBuilder] = useState([]);
    const [filterPhaseStatus, setFilterPhaseStatus] = useState([]);
    const [calendarOpen, setCalendarOpen] = useState(false);
    // Initialize with three dates: today, tomorrow, and day after tomorrow
    //const initialDates = [dayjs().add(-1, 'day'), dayjs().add(1, 'day'), dayjs().add(2, 'day')];
    const initialDates = [dayjs('1900-01-01'), dayjs().add(1, 'day')];
    const [startDate, setStartDate] = useState(dayjs().add(1, 'day'));
    const [columns, setColumns] = useState(initialDates);
    const [collapsed, setCollapsed] = useState(() => columns.map(() => false));
    //const [customDates, setCustomDates] = useState([]);
    // Centralized jobs state: { '2023-10-05': [...jobs], '2023-10-06': [...jobs], ... }
    const [jobsByDate, setJobsByDate] = useState([]);
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const people = await CentralCalls.fetchTableData('employee', { inactive: 0 });
                setEmployees(people);
                console.log('EMPMLOYEES LOADED');
            } catch (error) {
                console.error('Failed to fetch employees:', error);
                setEmployees([]);
            }
        };
        fetchEmployees();
    }, []);
    const addCustomDate = () => {
        setColumns(prevDates => {
            const newDate = startDate.add(prevDates.length - 1, 'day'); // Start at today + 2 days
            return [...prevDates, newDate];
        });
    };

    const removeCustomDate = () => {
        if(columns.length > 2){
            setColumns(prevDates => prevDates.slice(0, -1)); // Remove last date
        }
        
    };
    const handleBuilderSelect = (builder) => {
        setFilterBuilder(builder);
    };
    const handlePhaseTypeSelect = (phase) => {
        setFilterPhaseType(phase);
    };
    const handlePhaseStatusSelect = (status) => {
        setFilterPhaseStatus(status);
    };
    const [isLoading, setIsLoading] = useState(false);
    const onRefresh = () => { setRefreshKey(refreshKey + 1) }
    const createUniqueCrews = (jobs, employees) => {
        const crewMap = new Map(); // Map to store unique crews
        console.log('creatuinutecrews...'); console.log(employees);
        for (const job of jobs) {
            if (!job?.employee_oids || job?.employee_oids.length === 0) continue;

            // Ensure employee_oids is sorted to avoid duplicate crews in different orders
            const sortedEmployeeOids = [...job.employee_oids].sort((a, b) => a - b);
            const crewKey = JSON.stringify(sortedEmployeeOids); // Unique identifier for crew

            if (!crewMap.has(crewKey)) {
                // Get employee details from the employees array
                const crewMembers = sortedEmployeeOids.map(oid => employees.find(emp => emp.oid === oid)).filter(Boolean);

                if (crewMembers.length > 0) {
                    //const crewName = crewMembers[0].name; // Crew name is the last name of first employee
                    const nameParts = crewMembers[0].name.trim().split(/\s+/);
                    const crewName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0]; // Last name or first if no last name

                    crewMap.set(crewKey, {
                        crewName,
                        employees: crewMembers
                    });
                }
            }
        }
        console.log('crewas..', crewMap.values())
        return Array.from(crewMap.values());
    };

    const fetchJobs = useCallback(async (columnIndex, newDate) => {
        // if (employees.length === 0) {
        //     console.log('Employees not loaded yet. Skipping job fetch.');
        //     return;
        // }
        const formattedNewDate = newDate.format('YYYY-MM-DD');
        let jobs = [];
        console.log('running open schedule. Date:' + new Date(newDate));
        jobs = await CentralCalls.callStoredProcedureWithParams('sp_getOpenScheduleByDate', {
            date: CentralCalls.sqlFormatdate(newDate),
            isPastDue: columnIndex === 0 ? 1 : 0,
            phaseType: filterPhaseType?.length > 0 ? filterPhaseType : [],  // Pass as actual array, not JSON string
            readyStat: filterPhaseStatus?.length > 0 ? filterPhaseStatus : [],
            builderOid: filterBuilder?.length > 0 ? filterBuilder.map(Number) : []
        });
        setJobsByDate(prevJobs => {
            const updatedJobs = {
                ...prevJobs,
                [formattedNewDate]: jobs,
            };
           // console.log("Updated jobsByDate:", updatedJobs);
            return updatedJobs;
        });
        // setJobsByDate(prevJobs => ({
        //     ...prevJobs,
        //     [formattedNewDate]: jobs,
        // }));

        setColumns(prevColumns => {
            const updatedColumns = [...prevColumns];
            updatedColumns[columnIndex] = newDate;
            return updatedColumns;
        });
        // if (jobs && jobs.length > 0) {
        //     console.log('running unique jobs..');
        //     console.log(jobs);
        //     console.log(employees);
        //     const uniqueCrews = await createUniqueCrews(jobs, employees);
        //     setCrews(uniqueCrews);
        // } else { console.log('skipping create unirn creas'); }
        //const uniqueCrews = await createUniqueCrews(jobs);
        //setCrews(uniqueCrews);
    }, [employees, filterPhaseType, filterPhaseStatus, filterBuilder?.length]); // Dependencies

    useEffect(() => {
        setIsLoading(true);
        console.log('in fetch all jobs')
        const fetchAllJobs = async () => {
            try {
                columns.forEach((date, index) => {
                    const formattedDate = date.format('YYYY-MM-DD');
                    fetchJobs(index, date);
                });
            } catch (error) {
                console.error('Failed to fetch jobs:', error);
                const emptyJobs = {};
                for (const date of columns) {
                    const formattedDate = date.format('YYYY-MM-DD');
                    emptyJobs[formattedDate] = [];
                }
                //setJobsByDate(emptyJobs);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAllJobs();
    }, [refreshKey, filterPhaseStatus, filterPhaseType, filterBuilder]);
    useEffect(() => {
        if (Object.keys(jobsByDate).length === columns.length && employees.length > 0) {
            console.log("Both employees and jobs are loaded. Creating crews...");
            const allJobs = Object.values(jobsByDate).flat(); // Flatten jobsByDate to process all jobs
            const uniqueCrews = createUniqueCrews(allJobs, employees);
            setCrews(uniqueCrews);
        }
    }, [jobsByDate, employees]);
    const handleEmployeeChange = (event, newValue) => {
        console.log('In employee change...');

        const updatedCrews = [...crews];

        // Ensure newValue is an array (handles cases where it's empty)
        const crewMembers = newValue || [];

        if (crewMembers.length > 0) {
            // Get last name of the first employee
            const nameParts = crewMembers[0].name.trim().split(/\s+/);
            const crewName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : nameParts[0];

            // Update the selected crew with name + members
            updatedCrews[selectedCrewIndex] = {
                crewName,
                employees: crewMembers
            };
        } else {
            // If no employees, reset the crew
            updatedCrews[selectedCrewIndex] = { crewName: "", employees: [] };
        }

        console.log('Updated crews:', updatedCrews);
        setCrews(updatedCrews);
    };


    const addNewCrew = () => {
        setCrews(prevCrews => {
            const newCrews = [...prevCrews, []];
            setSelectedCrewIndex(newCrews.length - 1);
            return newCrews;
        });
    };

    const deleteCrew = (index) => {
        if (crews.length > 1) {
            const updatedCrews = crews.filter((_, i) => i !== index);
            setCrews(updatedCrews);
            setSelectedCrewIndex(0);
        }
    };

    /**
     * Move a job from one position to another, possibly across different dates.
     * @param {string} sourceDate - The date from which the job is moved (formatted 'YYYY-MM-DD').
     * @param {number} sourceIndex - The index of the job in the source date.
     * @param {string} targetDate - The date to which the job is moved (formatted 'YYYY-MM-DD').
     * @param {number} targetIndex - The index in the target date where the job should be inserted.
     */
    const moveJob = useCallback(
        async (sourceDate, sourceIndex, targetDate, targetIndex) => {
            console.log('in move job')
            console.log(jobsByDate);
            // console.log(jobsByDate[sourceDate]);
            //console.log(jobsByDate[sourceDate]?.splice(sourceIndex, 1));
            //console.log('sourceindex:' +sourceIndex + ' , sourceDate:' + sourceDate);
            const changedJob = jobsByDate[sourceDate]?.splice(sourceIndex, 1)?.[0];
            if (changedJob) {
                console.log('ran insert!')
                await CentralCalls.upsertData({ oid: changedJob.oid, date_scheduled: CentralCalls.sqlFormatdate(targetDate) }, 'phase', 'update');
            }
            else { console.log('skipped insert') }

            setJobsByDate(prevJobs => {
                // If moving within the same date:
                if (sourceDate === targetDate) {
                    const updatedJobs = [...prevJobs[sourceDate]];
                    // Remove the job from sourceIndex
                    const [movedJob] = updatedJobs.splice(sourceIndex, 1);
                    // If targetIndex is after sourceIndex, we adjust targetIndex
                    // because we've already removed the item from the array
                    let adjustedIndex = targetIndex;
                    if (targetIndex > sourceIndex) {
                        adjustedIndex = targetIndex - 1;
                    }
                    // Insert into the new position
                    updatedJobs.splice(adjustedIndex, 0, movedJob);
                    console.log('new jobsByDate from moveJob.. where source= target:', updatedJobs);
                    return {
                        ...prevJobs,
                        [sourceDate]: updatedJobs,
                    };
                } else {
                    // Moving across different dates
                    const sourceJobs = [...prevJobs[sourceDate]];
                    const [movedJob] = sourceJobs.splice(sourceIndex, 1);

                    // If target date doesn't exist, default to empty array
                    const targetJobs = prevJobs[targetDate]
                        ? [...prevJobs[targetDate]]
                        : [];

                    // Insert into the target position
                    targetJobs.splice(targetIndex, 0, movedJob);
                    console.log('new jobsByDate from moveJob.. where source != target:', targetJobs);
                    // Return a new state object with updated arrays
                    return {
                        ...prevJobs,
                        [sourceDate]: sourceJobs,
                        [targetDate]: targetJobs,
                    };
                }
            });
        },
        [jobsByDate]
    );

    /**
     * Update a job's crew assignment.
     * @param {string} date - The date of the job (formatted 'YYYY-MM-DD').
     * @param {number} jobIndex - The index of the job within the date.
     * @param {number} crewIndex - The index of the crew assigned to the job.
     */
    const assignCrewToJob = useCallback((date, jobIndex, crewIndex) => {
        const job = jobsByDate[date][jobIndex];
        const crew = '[' + crews[crewIndex].employees.map(emp => emp.oid) + ']';
        CentralCalls.upsertData({ oid: job.phase_status_oid, status_date: CentralCalls.sqlFormatdate(date), employee_oids: crew.employees }, 'phase_status', 'update');
        CentralCalls.upsertData({ oid: job.phase_oid, date_scheduled: CentralCalls.sqlFormatdate(date) }, 'phase', 'update');
        setJobsByDate(prevJobs => {
            const updatedJobs = [...prevJobs[date]];
            updatedJobs[jobIndex].employee_oids = crews[crewIndex].employees.map(emp => emp.oid);
            updatedJobs[jobIndex].crewIndex = crewIndex;
            console.log('from assignCrewToJob new jobsByDate:', updatedJobs);
            return {
                ...prevJobs,
                [date]: updatedJobs,
            };
        });
    }, [crews]);

    /**
     * Handle date change from ScheduleColumn.
     * @param {number} columnIndex - The index of the column whose date has changed.
     * @param {dayjs} newDate - The new date selected.
     */
    // const handleDateChange = useCallback((columnIndex, newDate) => {
    //     console.log('handlingdate change....');
    //     console.log(newDate);
    //     console.log(columns);
    //     if (columnIndex < 0 || columnIndex >= columns.length || !dayjs(newDate).isValid()) return;
    //     console.log('fetching jobs again.')
    //     setColumns(prevColumns => {
    //         const updatedColumns = [...prevColumns];
    //         console.log('testing...');
    //         //console.log(updatedColumns[columnIndex]);
    //         // Only update if the date is actually different
    //         if (!updatedColumns[columnIndex].isSame(newDate, 'day')) {
    //             console.log('handling date change actually running.')
    //             updatedColumns[columnIndex] = newDate;
    //             fetchJobs(columnIndex, newDate);
    //             return updatedColumns; // Return updated array to trigger state update
    //         }

    //         return prevColumns; // Return the same array to prevent re-renders
    //     });
    // }, [fetchJobs, columns.length]);
    // useEffect(() => {
    //     console.log("Columns updated:", columns);
    //     fetchJobs(1, startDate);
    // }, [columns]);
    // useEffect(() => {
    //     console.log("Columns updated:", columns);
    //     customDates.forEach((date, index) => {
    //         fetchJobs(index, date);
    //     });
    // }, [customDates]);
    const handleSelectedDateChange = (newDate) => {
        if (!newDate) return; // Prevent errors if no date is selected
        console.log("Selected Date:", newDate.format("YYYY-MM-DD"));
        
        setStartDate(newDate);
        setColumns(prevColumns => {
            const newColumns = [...prevColumns]; // Copy existing state
            let nextDate = newDate; // Start updating from index 1
            for (let i = 1; i < prevColumns.length; i++) {
                newColumns[i] = nextDate;
                nextDate = nextDate.add(1, 'day'); // Increment each new date
            }
            return newColumns;
        });
    };

    const handleToggleCollapse = (index) => {
        setCollapsed(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const prevColumnsRef = useRef(columns);
    useEffect(() => {
        // Prevent unnecessary calls by comparing previous and new columns
        if (JSON.stringify(prevColumnsRef.current) !== JSON.stringify(columns)) {
            console.log("Columns actually changed, fetching all jobs...");
            columns.forEach((date, index) => {
                fetchJobs(index, date);
            });
            prevColumnsRef.current = columns; // Update stored columns after fetching
        }
    }, [columns]); 
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="match-schedule-root">
                <div className="match-schedule-column crew">
                    <div className="match-schedule-header">

                        <div className="crew-section">
                            <span className="title">
                                Crew
                                <IconButton onClick={addNewCrew} size="small">
                                    <AddIcon />
                                </IconButton>
                            </span>
                            <Autocomplete
                                multiple
                                options={employees}
                                getOptionLabel={(option) => option.name || ''}
                                value={crews[selectedCrewIndex]?.employees ?? []}
                                onChange={handleEmployeeChange}
                                isOptionEqualToValue={(option, value) => option.oid === value.oid}
                                renderTags={(value, getTagProps) => (
                                    <div className="autocomplete-chip-container">
                                        {value.map((option, index) => (
                                            <Chip key={option.oid} label={option.name} {...getTagProps({ index })} />
                                        ))}
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <FormControl fullWidth required>
                                        <TextField {...params} label="Select Employees" variant="standard" required />
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className="filters-container">
                            <div className="filter-group">
                                <span className="filter-title">Builder</span>
                                <BuilderFilter onBuilderSelect={handleBuilderSelect} />
                            </div>
                            <div className="filter-group">
                                <span className="filter-title">Phase</span>
                                <PhaseFilter onPhaseSelect={handlePhaseTypeSelect} />
                            </div>
                            <div className="filter-group">
                                <span className="filter-title">Status</span>
                                <ReadyStatFilter onStatusSelect={handlePhaseStatusSelect} />
                            </div>
                            <div className="filter-group">
                                <span className="filter-title">Add Days</span>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <IconButton onClick={addCustomDate} size="small">
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton onClick={removeCustomDate} size="small" disabled={columns.length === 2}>
                                        <RemoveIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Schedule Columns */}
                    <div className="match-schedule-columns">
                        <div className="crew-list-wrapper">
                            {/* Fixed Icon Header */}
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <div className="icon-header" style={{marginLeft:'10px'}}>
                                <PeopleIcon fontSize="large" />
                            </div>
                            <span className='title' style={{ marginLeft: '15px' }}>
                                                       Crew
                              </span>
                              </div>
                            {/* Scrollable Crew List */}
                            <div className="crew-list-column">
                                {crews.map((crew, index) => (
                                    <DraggableCrewList
                                        key={index}
                                        crew={Array.isArray(crew.employees) ? crew.employees : []}
                                        onClick={() => setSelectedCrewIndex(index)}
                                        onDelete={() => deleteCrew(index)}
                                        showDelete={crews.length > 1}
                                        isSelected={index === selectedCrewIndex}
                                        index={index}
                                        crewColors={crewColors}
                                    />
                                ))}
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', gap: '8px', flexGrow: 1, margin: 0, padding: 0 }}>
                            {columns.map((date, index) => {
                                const isCollapsed = collapsed[index];
                                const totalNonCollapsed = collapsed.filter(c => !c).length || 1; // Ensure at least 1 open column

                                return (
                                    <div
                                        key={date.format('YYYY-MM-DD')}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 'calc(100vh - 120px)',
                                            overflowY: 'auto',
                                            flex: isCollapsed ? '0 0 30px' : `1 1 ${100 / totalNonCollapsed}%`, // Collapsed takes 30px, others share space
                                            minWidth: isCollapsed ? '30px' : '0' // Ensures collapsed columns stay small
                                        }}
                                    >
                                        {/* Header Section */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {!isCollapsed && index !== 0 && (

                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                        {index === 1 && (
                                                            <>
                                                                <IconButton onClick={() => setCalendarOpen(true)}>
                                                                    <CalendarIcon />
                                                                </IconButton>

                                                                <DatePicker
                                                                    open={calendarOpen}
                                                                    value={startDate}
                                                                    onChange={(newDate) => handleSelectedDateChange(newDate)} // Update state when date is selected
                                                                    onClose={() => setCalendarOpen(false)}
                                                                    sx={{ display: "none" }} // Hide default input field
                                                                />
                                                            </>
                                                        )}
                                                        <span className="title">{date.format("ddd")} {date.format("MM-DD")}</span>
                                                    </div>
                                                </LocalizationProvider>

                                            )}
                                            {!isCollapsed && index === 0 && (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span className='title' style={{ marginLeft: '15px', textDecoration: 'underline' }}>
                                                        Past Due
                                                    </span>
                                                </div>
                                            )}
                                            <IconButton onClick={() => handleToggleCollapse(index)} size="small">
                                                {isCollapsed ? (index === 0 ? <WarningIcon /> : <CalendarIcon />) : <ExpandLess />}
                                            </IconButton>
                                        </div>

                                        {/* Summary Only When Not Collapsed */}
                                        {!isCollapsed && (
                                            <span style={{ padding: '10px' }}>
                                                <ScheduleColumnSummary
                                                    total={jobsByDate[date.format('YYYY-MM-DD')]?.length}
                                                    totalComplete={jobsByDate[date.format('YYYY-MM-DD')]?.filter(job => job.date_scheduled != null)?.length}
                                                />
                                            </span>
                                        )}

                                        {/* Schedule Column */}
                                        <ScheduleColumn
                                            columnIndex={index}
                                            selectedDate={date}
                                            crewColors={crewColors}
                                            isPastDue={index === 0}
                                            crews={crews}
                                            assignCrewToJob={assignCrewToJob}
                                            moveJob={moveJob}
                                            jobs={jobsByDate[date.format('YYYY-MM-DD')] || []}

                                            employees={employees}
                                            isCollapsed={isCollapsed}
                                            setRefresh={onRefresh}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </DndProvider>
    );
}
export default MatchSchedule;