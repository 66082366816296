import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Tooltip, Checkbox, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const ReadyStatFilter = ({ onStatusSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    // Default selected statuses: "Open" (id: 'open') & "Scheduled" (id: 'scheduled')
    const defaultSelected = ['open', 'scheduled'];
    const [selectedStatuses, setSelectedStatuses] = useState(defaultSelected);

    const statuses = [
        { id: '', name: 'All', short_name: '*', color: '#D32F2F' }, 
        { id: 'cancel', name: 'Deleted', short_name: 'D', color: '#D32F2F' }, 
        { id: 'hold', name: 'Hold', short_name: 'H', color: '#FFA000' },
        { id: 'open', name: 'Open', short_name: 'O', color: '#1976D2' }, 
        { id: 'scheduled', name: 'Scheduled', short_name: 'S', color: '#388E3C' }, 
        { id: 'complete', name: 'Complete', short_name: 'C', color: '#7B1FA2' }, 
        { id: 'upcoming', name: 'Upcoming', short_name: 'U', color: '#FBC02D' } 
    ];

    useEffect(() => {
        // Call onStatusSelect with the default selection on mount
        onStatusSelect(defaultSelected);
    }, []); // Runs once when the component mounts

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleStatusToggle = (status) => {
        const updatedStatuses = selectedStatuses.includes(status.id)
            ? selectedStatuses.filter(id => id !== status.id) // Remove if already selected
            : [...selectedStatuses, status.id]; // Add if not selected
        
        setSelectedStatuses(updatedStatuses);
        onStatusSelect(updatedStatuses); // Pass array of selected statuses
    };

    const handleClearSelection = () => {
        setSelectedStatuses([]);
        onStatusSelect([]); // Empty array means no filter applied
    };

    return (
        <div>
            <Tooltip title={selectedStatuses.length ? selectedStatuses.join(', ') : 'Select Status'}>
                <IconButton
                    onClick={handleMenuOpen}
                    sx={{
                        fontSize: 13,
                        backgroundColor: selectedStatuses.length ? '#888' : '#ccc',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#aaa',
                            color: 'white'
                        },
                        minWidth: '30px', height: '30px'
                    }}
                >
                    {selectedStatuses.length ? selectedStatuses.map(id => statuses.find(s => s.id === id)?.short_name || '').join(', ') : <FilterListIcon />}
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleClearSelection} dense>
                    <Checkbox checked={selectedStatuses.length === 0} />
                    <ListItemText primary="All (No Filter)" />
                </MenuItem>
                {statuses.map(status => (
                    <MenuItem key={status.id} onClick={() => handleStatusToggle(status)}>
                        <Checkbox checked={selectedStatuses.includes(status.id)} />
                        <ListItemText primary={`${status.short_name} - ${status.name}`} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ReadyStatFilter;